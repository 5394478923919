import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';

import UploadFile from "./UploadFile";
import AlertMsg from "./AlertMsg";
import SwitchInput from "../containers/Settings/SwitchInput";

import { connect } from "react-redux";
import { updateRestaurantCategoryList } from "../redux/actions/userDataActions";

import * as Api from "../api";

import "./../assets/css/custom.css";
import { ReactComponent as DeleteIcon } from "../assets/images/delete.svg";

import { THEME_MODE } from "../constants/Theme";

const DEFAULT_COLOR = "#000000";
const AddSettingCategory = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [nameVal, setNameVal] = useState("");
  const [descriptionVal, setDescriptionVal] = useState("");
  const [textColor, setTextColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [categoryEnabled, setCategoryEnabled] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const theme = createTheme({
    palette: {
      gray: {
        main: theme_mode.text4,
      },
    },
  });

  const textInputCss = {
    "& .MuiInput-underline:before": {
      borderBottom: `1.5px solid ${theme_mode.text4}`,
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1.5px solid ${theme_mode.text4}`,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: 0,
    },
  };

  useEffect(() => {
    if (props.catData != null) {
      const {
        name = "",
        description = "",
        textColor = null,
        color = null,
        images = [],
        enabled = false,
      } = props.catData || {};
      setNameVal(name);
      setDescriptionVal(description);
      setTextColor(textColor);
      setBackgroundColor(color);
      setImageList(images);
      setCategoryEnabled(enabled);
    } else {
      setNameVal("");
      setDescriptionVal("");
      setTextColor(null);
      setBackgroundColor(null);
      setImageList([]);
      setImageLoading(false);
      setBtnDisabled(false);
      setCategoryEnabled(true);
    }
  }, [props]);

  useEffect(() => {
    let upload_list = imageList.filter((x) => x.upload == false);
    if (upload_list.length > 0) {
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageList]);

  const onSubmit = (e) => {
    e.preventDefault();

    if ((textColor !== null && !/^#(?:[0-9a-fA-F]{3}){1,2}$/g.test(textColor)) ||
      (backgroundColor !== null && !/^#(?:[0-9a-fA-F]{3}){1,2}$/g.test(backgroundColor))) {
      setMsgAlert({
        open: true,
        message: "Invalid Color Code",
        msgType: "error",
      });
      return;
    }

    const catImageData = imageList.map((item, index) => item.name);
    const data1 = {
      name: nameVal,
      description: descriptionVal,
      textColor: textColor,
      color: backgroundColor,
      _idRestaurant: props.restaurantId,
      images: catImageData,
      enabled: categoryEnabled,
    };
    const datas = JSON.stringify(data1);

    setBtnDisabled(true);
    if (props.catData === null) {
      Api.addMenuCategory(datas, props.restaurantId).then((response) => {
        if (response.success) {
          props.updateRestaurantCategoryList([
            ...props.restaurantCategoryList,
            response.data,
          ]);
          setMsgAlert({
            open: true,
            message: "Added Successfully",
            msgType: "success",
          });
          props.onSubmit();
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnDisabled(false);
      });
    } else {
      const catId = props.catData?._id;
      Api.updateMenuCategory(datas, props.restaurantId, catId).then(
        (response) => {
          if (response.success) {
            let list = [...props.restaurantCategoryList];
            let findIndex = list.findIndex((x) => x._id == catId);
            if (findIndex >= 0) {
              list[findIndex] = { ...list[findIndex], ...data1 };
              props.updateRestaurantCategoryList(list);
            }
            setMsgAlert({
              open: true,
              message: "Updated Successfully",
              msgType: "success",
            });
            props.onSubmit();
          } else {
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "error",
            });
          }
          setBtnDisabled(false);
        }
      );
    }
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onRemoveImage = (data) => {
    setImageList([]);
    // let menuImage = [...imageList];
    // let imageIndex = menuImage.findIndex((item) => item.id == data.id);
    // if (imageIndex >= 0) {
    //   menuImage.splice(imageIndex, 1);
    //   setImageList(menuImage);
    // }
  };

  const uploadFile = (data, file) => {
    setImageList((prev) => [...prev, data]);
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);

    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        setImageList((prev) =>
          prev.map((el) => (el.id == data.id ? { ...el, ...data } : el))
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const uploadError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  const handleOnChangeValue = (type, value) => {
    if (type === "name") {
      setNameVal(value);
    } else if (type === "description") {
      setDescriptionVal(value);
    }
  };

  const handlerCategoryEnabled = () => {
    setCategoryEnabled(!categoryEnabled);
  };

  return (
    <Grid
      component="form"
      onSubmit={onSubmit}
      container
      direction="column"
      sx={styles.mainGrid}
    >
      <Grid
        sx={{
          flex: 1,
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid>
          <Typography sx={styles.titleText}>
            {props.catData != null ? "Edit" : "Add"} Category
          </Typography>

          <Grid container direction="row" item xs={12}>
            <UploadFile
              page={"AddSettingCategory"}
              uploadText={"Upload Image"}
              maxFiles={1}
              imageLoading={imageLoading || btnDisabled}
              imageList={imageList}
              imageLength={imageList.length}
              themeMode={props.themeMode}
              onRemoveImage={onRemoveImage}
              uploadError={uploadError}
              uploadFile={uploadFile}
            />
            {/*<Grid container*/}
            {/*      direction="column"*/}
            {/*      item xs={4}*/}
            {/*      alignItems="flex-end">*/}
            {/*  {imageList.map((item, index) => {*/}
            {/*    return (*/}
            {/*      <AddMenuImageItem*/}
            {/*        key={index}*/}
            {/*        data={item}*/}
            {/*        onRemoveImage={onRemoveImage}*/}
            {/*        page="AddSettingCategory"*/}
            {/*      />*/}
            {/*    )*/}
            {/*  })}*/}
            {/*</Grid>*/}
          </Grid>

          <Grid item mt={3}>
            <Box sx={styles.enabledSwitchView}>
              <Typography sx={styles.enabledLabel}>Category Enabled</Typography>
              <SwitchInput
                themeMode={props.themeMode}
                checked={categoryEnabled}
                onChange={handlerCategoryEnabled}
              />
            </Box>
          </Grid>

          <Grid sx={{ mt: 3 }}>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  style={{ width: "100%" }}
                  value={nameVal}
                  color="gray"
                  id="categoryTitle"
                  label="Category Title"
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => handleOnChangeValue("name", e.target.value)}
                />
              </ThemeProvider>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  style={{ width: "100%" }}
                  value={descriptionVal}
                  color="gray"
                  id="description"
                  label="Description"
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) =>
                    handleOnChangeValue("description", e.target.value)
                  }
                />
              </ThemeProvider>
            </Grid>

            <Grid mt={3} mb={3}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  rowsmin={2}
                  color="gray"
                  id="textColor"
                  label="Text Color"
                  placeholder="Choose a color"
                  value={textColor ? textColor : ''}
                  variant="standard"
                  sx={textInputCss}
                  onChange={(e) => setTextColor(e.target.value)}
                  InputProps={{
                    sx: { ...styles.inputText, "& input": { textTransform: textColor ? "uppercase" : "capitalize" } },
                    startAdornment: (<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem' }}
                      value={textColor ? textColor : DEFAULT_COLOR}
                      onChange={(e) => setTextColor(e.target.value)}
                    />),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setTextColor(null)}>
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ThemeProvider>
            </Grid>

            <Grid mt={3} mb={3}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  rowsmin={2}
                  color="gray"
                  id="backgroundColor"
                  label="Background Color"
                  placeholder="Choose a color"
                  value={backgroundColor ? backgroundColor : ''}
                  variant="standard"
                  sx={textInputCss}
                  onChange={(e) => setBackgroundColor(e.target.value)}
                  InputProps={{
                    sx: { ...styles.inputText, "& input": { textTransform: textColor ? "uppercase" : "capitalize" } },
                    startAdornment: (<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem' }}
                      value={backgroundColor ? backgroundColor : DEFAULT_COLOR}
                      onChange={(e) => setBackgroundColor(e.target.value)}
                    />),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setBackgroundColor(null)}>
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Button type="button" sx={styles.cancelBtn} onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          disabled={imageLoading || btnDisabled}
          type="submit"
          sx={styles.saveBtn}
        >
          {imageLoading || btnDisabled
            ? "Please Wait"
            : props.catData == null
              ? "Add Category"
              : "Update Category"}
        </Button>
      </Grid>

      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCategoryList: (data) =>
      dispatch(updateRestaurantCategoryList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantCategoryList: state.userData.restaurantCategoryList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSettingCategory);

const styles1 = (Theme) => ({
  mainGrid: {
    width: "100%",
    minHeight: "100%",
    p: 3,
  },
  titleText: {
    color: Theme.titleText,
    fontFamily: "InterBold",
    fontSize: 18,
    pt: 1,
    pb: 3,
  },
  enabledSwitchView: {
    display: "flex",
    alignItems: "center",
  },
  enabledLabel: {
    color: Theme.inputLabel,
    fontSize: 12,
    fontFamily: "InterRegular",
    mr: 1,
  },
  inputLabelText: {
    color: Theme.inputLabel,
    fontFamily: "InterRegular",
  },
  inputText: {
    color: Theme.inputVal,
    fontSize: 13,
    fontFamily: "InterSemiBold",
    my: 0.8,
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    backgroundColor: Theme.btnBg5,
    color: Theme.text,
    fontFamily: "InterBold",
    fontSize: 14,
    borderRadius: 1,
    border: "2px solid" + Theme.btnBg5Border,
    width: "49%",
    height: 44,
    "&:hover": {
      backgroundColor: Theme.btnBg5 + " !important",
    },
  },
  saveBtn: {
    backgroundColor: Theme.btnBg4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.btnText,
    fontFamily: "InterBold",
    fontSize: 14,
    borderRadius: 1,
    width: "49%",
    height: 44,
    "&:hover": {
      backgroundColor: Theme.btnBg4 + " !important",
    },
  },
});
